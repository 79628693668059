

<template>
  <b-sidebar right v-model="show" class="sidebar-search" shadow backdrop ref="sidebar_search" @change="hasClosedSidebar">
    <template #header>
      <div class="p-1 d-flex justify-content-between w-100">
        <h5 class="m-0">Mis búsquedas</h5>
        <feather-icon icon="XIcon" size="20" class="icon-close-sidebar" @click="show = false"></feather-icon>
      </div>
    </template>

    <div class="p-1">
      <div v-if="history_saved.length === 0 && loaded" class="d-flex flex-column align-items-center mt-3">
        <b-img :src="require('@/assets/images/svg/not-found.svg')" class="not-found-history mb-2"></b-img>
        <h3>Aún no cuentas con busquedas guardadas</h3>
      </div>
      <div class="grid-history-filters-search" v-else>
        <card-history-search 
          v-for="(i, index) in history_filtered_pagination"
          :key="index"
          :i="i"
          :index="index"
          @update_history="updateHistory"
          @delete_history="deleteHistory"
          @set_filters="applyFilters"
        >
        
        </card-history-search>
      </div>
      <pagination-brandme 
        class="mt-2 w-100 d-flex justify-content-center"
        :total_rows="history_saved.length"
        :per_page="per_page"
        :current_page="current_page"
        @update_current_page="updateCurrentPage"
        v-if="history_saved.length > 0"
      ></pagination-brandme>
    </div>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BImg,
} from 'bootstrap-vue';
import search_services from '@/services/search';

export default {
  name: 'sidebarSearchesSaved',
  components: {
    BSidebar,
    BImg,
    paginationBrandme: () => import('@/views/components/pagination/PaginationBrandme.vue'),
    cardHistorySearch: () => import('@/views/buscador/filters/components/cardHistorySearch.vue')
  },
  props: {
    open_sidebar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: this.open_sidebar,
      history_saved: [],
      per_page: 8,
      current_page: 1,
      loaded: false,
    }
  },
  computed: {
    history_filtered_pagination () {
      const items = this.history_saved;
      return items.length > 0 ? items.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page) : 0;
    },
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    applyFilters(history) {
      this.$emit('set_filters', history);
      this.show = false;
    },
    updateCurrentPage(new_current_page) {
      this.current_page = new_current_page;
    },
    hasClosedSidebar() {
      this.$refs.sidebar_search.$el.classList.remove('open-sidebar-search')
    },
    getHistory() {
      search_services.getHistorySearches({only_favorites: true}).then((response) => {
        this.loaded = true
        this.history_saved = response.results;
      })
    },
    deleteHistory(search_uuid) {  
      this.history_saved = this.history_saved.filter(i => i.uuid !== search_uuid)
    },
    updateHistory(searcher_uuid, response) {
      this.history_saved = this.history_saved.map(i => {
        if (i.uuid === searcher_uuid) i = response;
        return i
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.icon-close-sidebar:hover {
  background-color: #eeeeee;
  border-radius: 100%;
  cursor: pointer;
}
.not-found-history {
  width: 200px;
  height: 200px;
}
.grid-history-filters-search {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media(max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
<style lang="scss">

.sidebar-search > .b-sidebar {
  width: 610px !important;
}
</style>